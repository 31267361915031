div {
  height: auto;
  width: auto;
}

.graph-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 50px;
}

@media screen and (max-width: 700px) {
  .graph-container {
    height: 70vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .search-bar-container {
    visibility: visible;
    position: -webkit-sticky;
    justify-content: center;
    top: 550px;
    overflow-x:hidden;
  }
  .search-input {
    height: 10px;
  }
}

.search-form {
  display: flex;
  align-items: center;
}

.search-input {
  width: 200px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.search-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 12px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-button:hover {
  background-color: #0056b3;
}

.search-button svg {
  font-size: 18px;
}
