html,
body {
  height: 100%;
  overflow-x:hidden;
}
body {
  margin: 0;
  background: -webkit-linear-gradient(45deg, #c0ded9, #ffffff);
  background: linear-gradient(45deg, #667292, #8d9db6);
  font-family: sans-serif;
  font-weight: 100;
}
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 600px;
  overflow-y: scroll;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
table {
  width: 90%;
  margin: 20px auto;
  height: auto;
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
th,
td {
  padding: 15px;
  text-align: center;
  width: 150 px;
  height: 0 px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
th {
  text-align: center;
  width: 300px;
}
thead th {
  background-color: #55608f;
}
tbody tr{
  width: 10%;
  height: 1px;
}
tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
tbody td {
  width:auto;
  position: relative;
}
tbody td:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}
:root {
  --background-gradient: linear-gradient(178deg, #ffff33 10%, #3333ff);
  --gray: #34495e;
  --darkgray: #2c3e50;
}

@media screen and (max-width: 700px) {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
footer {
  font-family: Monospace;
  color: rgb(202, 204, 206);
  text-align: center;
  align-self: auto;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  footer{
    display: none;
  }
}
