.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 1rem;
}

.navbar-left .logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.navbar-center .nav-links {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-center .nav-links li {
  margin-right: 1rem;
}

.navbar-center .nav-links a {
  color: #fff;

  text-decoration: none;
}
.nav-links a:hover {
  color: #a6a3a3;
}

@media screen and (max-width: 690px) {
  .navbar-center .nav-links {
    flex-direction: column;
    padding: 0;
  }
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-right .cart-icon,
.navbar-right .user-icon {
  color: #fff;
  text-decoration: none;
  margin-left: 1rem;
  position: relative;
}

.navbar-right .cart-count {
  background-color: #f44336;
  color: #fff;
  border-radius: 50%;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
}
