.node-index-container {
  position: relative;
  z-index: 1000;
}

.toggle-btn {
  position: fixed;
  top: 70px;
  left: 20px;
  background-color: #8891ab;
  color: white;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  width: 200px;
  text-align: center;
}

.toggle-btn span {
  margin-left: 10px;
}

.legend-container {
  position: fixed;
  top: 115px;
  left: 20px;
  background-color: #8891ab;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  width: 220px;
  box-shadow: 0px 4px 6px rgba(35, 35, 35, 0.1);
}

.legend-container h3 {
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.legend-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.legend-list li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.color-box {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 4px;
}

.info-icon {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
  color: #555;
}

.info-icon:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-text {
  visibility: hidden;
  width: 400px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 24%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

@media screen and (max-width: 600px) {
  .legend-container,
  .toggle-btn {
    width: 100%;
    box-sizing: border-box;
    left: 0;
    top: 70px;
  }

  .tooltip-text {
    width: 300px;
    margin-left: -150px;
  }

  .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 47%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .legend-container {
    top: 110px;
  }
}
